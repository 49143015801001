.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.table {
  background: white;
  border-radius: 30px;
  font-size: 12px;
  padding-top:20px;
  padding-left: 20px;
  &__header {
    font-weight: bold;
  }
  &__cell {
    &:nth-child(2) {
      font-size: 10px;
    }
  }
}