
.sidebar {
  height: 100%;
  left: 100%;
  width: auto;
  max-width: 80vw;
  z-index: 5000; // had to do this because leaflet's z-indexes are so high
  transition: transform 0.3s ease-in;
  transform: translateX(-0%) translateZ(0);
  background: white;
  position: absolute;
  top: 0;
  will-change: transform;
  box-sizing: border-box;
  padding: 0 20px 20px;
  overflow: auto;
  &--open {
    transition: transform 0.3s ease-out;
    transform: translateX(-100%) translateZ(0);
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: rgba(0,0,0,0);
    transition: 0.3s ease-in-out;
    z-index: 4000; // had to do this because leaflet's z-indexes are so high
    &--open {
      background-color: rgba(0,0,0,0.35);
      pointer-events: all;
    }
  }
}