$blue: #000B29;
$grey: #f5f5f5;
$dark-grey: #444;
$mid-grey: #ebebeb;
$yellow: #FAAF08;


@mixin sans {
  font-family: 'Raleway', sans-serif;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  width: 100%;
  height: 100vh;
  @include sans;
  color: #444;
  overflow: hidden;
}

main {
  flex: 1;
  padding: 0px 20px 10px;
  // position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.app {
  height: calc(100% - 59px);
  flex: 1;
  padding-left: 150px;
  background-color: $grey;
  display: flex;
  flex-direction: row;
  position: relative;
}

.header {
  width: 100%;
  border-bottom: 1px solid $blue;
  height: 59px;

  h4 {
    margin: 5px 0px;
  }

  .roar-branding {
    max-height: 34px;
    width: auto;
  }

  &__inner {
    padding: 12px 30px;
    display: flex;
    flex-direction: row;
  }

  &__left,
  &__right {
    flex: 1;
  }

  &__left {
    text-align: left;
  }

  &__right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logout {
      margin-left: 15px;
      font-size: 1.125rem;
      color: $blue;
    }
  }
}


.nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 100%;
  background-color: $blue;
  overflow: auto;
  color: white;
  box-sizing: border-box;
  padding: 20px 0;

  &__link {
    padding: 8px;
    display: block;
    margin-top: 12px;
    color: white;
    text-align: right;
    font-size: 1.125rem;
  }

  &__error {
    padding: 6px 12px;

  }

  &__account {
    padding: 6px 12px;
    display: block;
    margin-bottom: 8px;
    color: #767676;
    text-decoration: none;
    transition: 0.25s ease;
    font-size: 0.875rem;

    &--active {
      background-color: lighten($blue, 5%);
      color: #e5e5e5;
    }
  }
}

@keyframes spin {
  0% {
      transform: rotateZ(0deg)
  }

  100% {
      transform: rotateZ(360deg)
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.spinner {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  margin: 5px;
  border: 5px transparent solid;
  border-top: 5px $yellow solid;
  animation: spin 1s linear infinite;
  border-radius: 50%;

  &:before {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    content: '';
    border: 5px transparent solid;
    border-top: 5px $grey solid;
    border-radius: 50%;
    box-sizing: border-box;
    animation: spin 0.5s linear infinite reverse;
  }
  &--blue {
    &:before {
      border-top: 5px $blue solid;
    }
  }
  &--mini {
    margin: 3px 5px -5px;
    border-width: 3px;
    height: 17px;
    width: 17px;
    &:before {
      border-width: 3px;
    }
  }
}
.inline-block {
  display: inline-block;
}
.defaultbtn {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit;
}
.button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit;
  background-color: $blue;
  color: white;
  border-bottom: 0px solid $yellow;
  transition: 0.2s ease;
  &:hover{
    border-bottom: 3px solid $yellow;
  }
}

.flex-wrapper {
  display: flex;
  align-items: center;
  &__fill {
    flex: 1;
  }
}

.mr {
  &--20 {
    margin-right: 20px;
  }
}

.primary {
  display: inline-block;
  border: 0;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  cursor: pointer;
  font-size: .875rem;
  line-height: .875rem;
  padding: 7px 18px;
  background-color: #faaf08;
  text-decoration: none;
  color: #000b29;
  font-family: Raleway,sans-serif;
  letter-spacing: .01875rem;
  margin: 10px;
}